import { InterfaceText, TextSize } from 'components/atoms/typography';
import React, { Fragment } from 'react';

import Checkmark from '@carbon/icons-react/es/checkmark/24';
import { Listbox } from '@headlessui/react';
import type { SelectProps } from './types';
import SelectorIcon from '@carbon/icons-react/es/chevron--sort/24';
import { Transition } from '@headlessui/react';
import { loggers } from 'lib/log';
import { makeClassNames } from 'lib/util';

const log = loggers.ui;

export const SelectEnhanced: React.FC<SelectProps<string | number>> = props => {
  const { onChange, options, size = 'm' } = props;

  const selected = options.find(o => o.active);

  if (!selected) {
    log.error(new Error('No active SelectEnhanced selection'), { options });
    return null;
  }

  return (
    <Listbox
      value={selected}
      onChange={selected => onChange(selected.value.toString())}
    >
      <div className="relative">
        <Listbox.Button
          className={makeClassNames(
            'relative w-full cursor-pointer rounded-lg bg-pearl-100 text-left focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-pearl-400 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-pearl-500 hover:shadow-md',
            size === 'm' ? 'py-2 pl-3 pr-10' : 'py-1 pl-2 pr-8 leading-4'
          )}
        >
          <InterfaceText
            className={makeClassNames(
              'flex truncate',
              size === 'm' && 'mt-[-4px]'
            )}
            size={TextSize.LongPrimer}
          >
            {selected.name || selected.value}
          </InterfaceText>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon className="h-3 w-3" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-50 mt-1 max-h-80 w-full min-w-[110px] cursor-pointer overflow-auto rounded-md bg-pearl-100 py-1 shadow-lg ring-1 ring-primary ring-opacity-5 focus:outline-none">
            {options.map((item, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `relative select-none py-2 px-4 pl-10 ${
                    active
                      ? 'cursor-default bg-primary text-secondary'
                      : 'text-primary-900'
                  }`
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <InterfaceText
                      className="block truncate"
                      color=""
                      bold={selected}
                      size={TextSize.LongPrimer}
                    >
                      {item.name || item.value}
                    </InterfaceText>

                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <Checkmark className="h-3 w-3" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SelectEnhanced;
